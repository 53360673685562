import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth'

export enum AuthProviders {
  APPLE = 'apple',
  GOOGLE = 'google',
}

/**
 * Sign in with a supported provider
 */
export async function signIn({
  providerType = AuthProviders.GOOGLE,
  redirect = false,
}: {
  providerType: AuthProviders
  redirect?: boolean
}) {
  const auth = getAuth()
  const provider =
    providerType === AuthProviders.GOOGLE
      ? new GoogleAuthProvider()
      : new OAuthProvider('apple.com')

  if (!redirect) {
    try {
      await signInWithPopup(auth, provider)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorCode = error.code
      const errorMessage = error.message
      console.error(errorCode, errorMessage)
    }
  } else {
    await signInWithRedirect(auth, provider)
  }

  return false
}

/**
 * Sign out
 */
export async function signOutOfApp() {
  const result: { error: string | null } = { error: null }
  const auth = getAuth()
  try {
    await auth.signOut()
    location.reload()
  } catch (error: unknown) {
    console.error(error)
    result.error = 'Failed to sign out'
  }

  return result
}
