import {
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTheme } from '../../theme'
import { AuthProviders, signIn } from '../../utils/auth'
import { McIcon } from '../McIcon'

interface LoginButtonsProps {
  onClick?: () => void
}

export const LoginButtons: React.FC<LoginButtonsProps> = observer(
  ({ onClick }) => {
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    return (
      <>
        <ListItem>
          <ListItemText
            primary='Welcome! 👋'
            secondary='Sign in to continue'
            slotProps={{ primary: { variant: 'h5' } }}
          />
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <Button
              color='secondary'
              fullWidth
              variant='contained'
              startIcon={<McIcon path='google' />}
              onClick={async () => {
                await signIn({
                  providerType: AuthProviders.GOOGLE,
                  redirect: isSmall,
                })
                if (onClick) {
                  onClick()
                }
              }}
            >
              Sign in with Google
            </Button>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <Button
              color='secondary'
              fullWidth
              variant='contained'
              startIcon={<McIcon path='apple' />}
              onClick={async () => {
                await signIn({
                  providerType: AuthProviders.APPLE,
                  redirect: isSmall,
                })
                if (onClick) {
                  onClick()
                }
              }}
            >
              Sign in with Apple
            </Button>
          </ListItemButton>
        </ListItem>
      </>
    )
  }
)
