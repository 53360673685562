import {
  Alert,
  Box,
  Container,
  Skeleton,
  SxProps,
  Theme,
  useMediaQuery,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery'

import { useParams } from 'react-router-dom'
import { ListingStatus } from '../../../../shared/models'
import { GoogleMap } from '../../Components'
import { useApiCallState } from '../../hooks'
import { useStores } from '../../models'
import { useTheme } from '../../theme'
import { getParamId } from '../../utils/getIdfromParam'
import { Actions } from './Actions'
import { NextSteps } from './Actions/NextSteps'
import { Security } from './Actions/Security'
import { AdditionalDetails } from './AdditionalDetails'
import { HeaderAttributes } from './HeaderAttributes'
import { Highlights } from './Highlights'

import 'react-image-gallery/styles/css/image-gallery.css'
import './imageGallery.css'

export const ListingDetails: React.FC = observer(() => {
  const {
    listingStore,
    accountStore,
    offerStore,
    tourStore,
    fileStore,
    chatStore,
  } = useStores()
  const { loading, setLoading, setMsg } = useApiCallState()
  const [ownerProfile, setOwnerProfile] = useState<{
    name?: string
    pic?: string
  }>()

  const params: { homeId?: string } = useParams()
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))

  const id = params.homeId ? getParamId(params.homeId) : null
  const listing = id ? listingStore.listingById(id) : null
  const userId = accountStore.user.userId
  const listingAcceptedOffer = listing?.accepted_offer || null
  const currentUserAcceptedOffer = Boolean(
    listingAcceptedOffer ? offerStore.offerById(listingAcceptedOffer) : null
  )
  const docUserIds = fileStore.fileUserIds

  useEffect(() => {
    if (listing && listing.street) {
      document.title = listing.address
    }
  }, [listing])

  useEffect(() => {
    async function getProfiles() {
      const userIds: string[] = [...docUserIds]
      if (listing?.owner_id) {
        userIds.push(listing.owner_id)
      }

      const profiles = await chatStore.getProfiles({
        userIds,
      })

      if (profiles.errorDetails) {
        console.error(profiles.errorDetails)
      }

      if (listing?.owner_id && profiles.profiles[listing?.owner_id]) {
        setOwnerProfile(profiles.profiles[listing?.owner_id])
      }
    }

    getProfiles()
  }, [chatStore, listing?.owner_id, docUserIds])

  useEffect(() => {
    async function getToursOffersDocs() {
      if (!id) return

      if (userId) {
        await Promise.all([
          offerStore.fetchOffers({ listing_id: id }),
          tourStore.fetchTours({ listing_id: id }),
          fileStore.fetchFiles({ listing_id: id }),
          listingStore.fetchListings({ listing_id: id }),
        ])
      } else {
        await Promise.all([
          fileStore.fetchFiles({ listing_id: id, publicOnly: true }),
          listingStore.fetchListings({ listing_id: id }),
        ])
      }
    }

    getToursOffersDocs()
  }, [id, userId, offerStore, tourStore, fileStore, listingStore])

  useEffect(() => {
    async function getListing() {
      if (!id) {
        setLoading(false)
        return
      }

      setLoading(true)
      const { error, errorDetails } = await listingStore.fetchListings({
        listing_id: id,
      })

      if (error) {
        console.error(errorDetails || error)
        setMsg(error)
      }

      setLoading(false)
    }

    if (listing) {
      setLoading(false)
    } else {
      getListing()
    }
  }, [setLoading, setMsg, id, listing, listingStore])

  if (loading) {
    return (
      <Container sx={{ mt: theme.spacing(2) }}>
        <Skeleton height={600} variant='rectangular' />

        <Grid container spacing={3} sx={{ mt: theme.spacing(2) }}>
          <Grid size={{ md: 6 }}>
            <Skeleton height={70} />
            <Skeleton height={50} />
            <Skeleton height={40} />
            <Skeleton height={50} />
            <Skeleton height={40} />
            <Skeleton height={100} variant='rectangular' />
          </Grid>
          <Grid size={{ md: 6 }}>
            <Skeleton height={400} variant='rectangular' />
          </Grid>
        </Grid>

        <Skeleton
          height={200}
          variant='rectangular'
          sx={{ mt: theme.spacing(2) }}
        />

        <Skeleton height={70} />
        <Skeleton height={50} />

        <Grid container spacing={3} sx={{ mt: theme.spacing(2) }}>
          <Grid size={{ md: 6 }}>
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </Grid>
          <Grid size={{ md: 6 }}>
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </Grid>
        </Grid>
      </Container>
    )
  }

  if (!id || !listing) {
    return (
      <Container sx={{ mt: theme.spacing(2) }}>
        <Alert variant='filled' severity='error'>
          {'Home Not Found!'}
        </Alert>
      </Container>
    )
  }

  const images = listing.imageUrls.map(img => ({
    original: img,
    thumbnail: img,
  }))

  return (
    <Container sx={{ mb: 10 }}>
      <Box component='section' sx={{ mt: theme.spacing(2) }}>
        {/* @ts-expect-error Valid React Component */}
        <ImageGallery
          items={images}
          showPlayButton={false}
          showIndex={true}
          showThumbnails={false}
          showFullscreenButton={true}
          showBullets={images.length > 1}
        />
      </Box>

      <Box component='section' sx={$attributeContainer}>
        {isMedium ? (
          <Box>
            <HeaderAttributes
              listing={listing}
              ownerName={ownerProfile?.name}
              ownerPic={ownerProfile?.pic}
            />

            <Box mx={{ marginBottom: theme.spacing(2) }}>
              <Actions
                listing_id={id}
                price={listing.price || undefined}
                listingAcceptedOffer={listingAcceptedOffer}
              />

              {!listingAcceptedOffer && <Security />}
            </Box>

            {currentUserAcceptedOffer &&
              listing.status !== ListingStatus.SOLD && (
                <Box mx={{ marginBottom: theme.spacing(2) }}>
                  <NextSteps />
                </Box>
              )}

            <Highlights listing={listing} />

            <Box component='section' sx={{ mt: 3 }}>
              <GoogleMap
                listings={[listing]}
                defaultZoom={14}
                listingDetailsMap
                sx={{ height: '35vh' }}
                defaultDisable
              />
            </Box>

            <Box component='section' sx={{ mt: 3 }}>
              <AdditionalDetails listing={listing} />
            </Box>
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Grid size={{ md: 6 }}>
              <Box>
                <HeaderAttributes
                  listing={listing}
                  ownerName={ownerProfile?.name}
                  ownerPic={ownerProfile?.pic}
                />

                <Highlights listing={listing} />

                {listingAcceptedOffer && (
                  <>
                    <Box component='section' sx={{ mt: 3 }}>
                      <GoogleMap
                        listings={[listing]}
                        defaultZoom={14}
                        listingDetailsMap
                        sx={{ height: '35vh' }}
                        defaultDisable
                      />
                    </Box>
                    <Box component='section' sx={{ mt: 3 }}>
                      <AdditionalDetails listing={listing} />
                    </Box>
                  </>
                )}
              </Box>
            </Grid>

            <Grid size={{ md: 6 }}>
              <Box mx={{ marginBottom: theme.spacing(2) }}>
                <Actions
                  listing_id={id}
                  price={listing.price || undefined}
                  listingAcceptedOffer={listingAcceptedOffer}
                />

                {!listingAcceptedOffer && <Security />}
              </Box>

              {currentUserAcceptedOffer &&
                listing.status !== ListingStatus.SOLD && (
                  <Box mx={{ marginBottom: theme.spacing(2) }}>
                    <NextSteps />
                  </Box>
                )}
            </Grid>

            {!listingAcceptedOffer && (
              <>
                <Grid size={{ md: 12 }}>
                  <Box component='section' sx={{ mt: 3 }}>
                    <GoogleMap
                      listings={[listing]}
                      defaultZoom={14}
                      listingDetailsMap
                      sx={{ height: '35vh' }}
                      defaultDisable
                    />
                  </Box>
                </Grid>

                <Grid size={{ md: 12 }}>
                  <Box component='section' sx={{ mt: 3 }}>
                    <AdditionalDetails listing={listing} />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Box>
    </Container>
  )
})

const $attributeContainer: SxProps<Theme> = {
  mt: 2,
  display: 'inline-flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
}
