import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { getAuth } from 'firebase/auth'
import { observer } from 'mobx-react-lite'
import { SnackbarProvider } from 'notistack'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { client } from '../../shared/services'
import { HeaderLayout } from './Components'
import {
  Account,
  ErrorPage,
  LandingPage,
  ListingDetails,
  Listings,
} from './Pages'
import { initFirebase } from './clients'
import Config from './config'
import { useCheckFunds } from './hooks/useCheckFunds'
import { useStartupActions } from './hooks/useStatupActions'
import { useStores } from './models'
import { useTheme } from './theme'

initFirebase()

// Setup API client with auth interceptor
client.setConfig({
  baseUrl: Config.apiUrl,
})

const auth = getAuth()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
client.interceptors.request.use(async (request, _options) => {
  const accessToken = await auth.currentUser?.getIdToken()

  if (accessToken) {
    request.headers.set('Authorization', `Bearer ${accessToken}`)
  } else {
    request.headers.set('Authorization', `Bearer ${Config.anonKey}`)
  }

  return request
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <HeaderLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'listings',
        element: <Listings />,
      },
      {
        path: 'listings/:vanityAddress/:homeId',
        element: <ListingDetails />,
      },
    ],
  },
])

const App = observer(() => {
  useStartupActions()

  const theme = useTheme()
  const { accountStore } = useStores()
  useCheckFunds(accountStore.user.userId)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <RouterProvider router={router} />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
})

export default App
