import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { DateCalendar } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { McIcon } from '../../../Components'
import { useApiCallState } from '../../../hooks'
import { useStores } from '../../../models'
import { useTheme } from '../../../theme'
import { FundsCard } from '../../Account/FundsCard'

interface TourScheduleCardProps {
  listing_id: string
  exit: (requested?: boolean) => void
}

interface ITourFormInput {
  agent_license?: string
}

export const TourScheduleCard: React.FC<TourScheduleCardProps> = observer(
  ({ listing_id, exit }) => {
    const { listingStore, tourStore, accountStore } = useStores()

    const { loading, setLoading, setMsg } = useApiCallState()
    const theme = useTheme()
    const [activeDate, setActiveDate] = React.useState<DateTime | null>(null)
    const [selectedDate, setSelectedDate] = React.useState<DateTime | null>(
      null
    )
    const agent_license = accountStore?.user?.profile?.agent_license ?? ''
    const [isAgent, setIsAgent] = useState(!!agent_license)

    const {
      control,
      handleSubmit,
      formState: { isValid },
    } = useForm<ITourFormInput>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    })
    const agentValid = !isAgent || isValid

    const dateSlots = tourStore.listingAvailableSlots(listing_id)
    const isoDates = Object.keys(dateSlots).sort()
    const firstDate = isoDates.length > 0 ? DateTime.fromISO(isoDates[0]) : null
    const currentActiveDate = activeDate || firstDate

    const activeDateIso = currentActiveDate?.toISODate()
    const slots = (activeDateIso && dateSlots[activeDateIso]) || []
    const noDatesAvailable = isoDates.length === 0

    const userId = accountStore.user.userId
    const listing = listingStore.listingById(listing_id)
    if (!listing) return null

    useEffect(() => {
      async function getAvailability() {
        setLoading(true)
        const { error, errorDetails } = await tourStore.getTourAvailability({
          listing_id,
        })

        if (error) {
          console.error(errorDetails || error)
        }
        setLoading(false)
      }

      getAvailability()
    }, [tourStore, listing_id, setLoading])

    const onSubmit = async (data: ITourFormInput) => {
      if (!userId || !selectedDate) return null

      if (data.agent_license && data.agent_license !== agent_license) {
        accountStore.user.updateProfile({
          agent_license: parseInt(data.agent_license),
        })
      }

      const startIso = selectedDate.toISO()
      const endIso = selectedDate.plus({ minutes: 45 }).toISO()

      if (!startIso || !endIso) {
        return null
      }

      setLoading(true)

      const { error, errorDetails } = await tourStore.submitTourRequest({
        listing_id,
        startIso,
        endIso,
      })
      setLoading(false)

      if (error) {
        console.error(errorDetails || error)
        setMsg(error, 'error')
        setSelectedDate(null)
      } else {
        setMsg('Tour Requested', 'success')
        exit(true)
      }
    }

    return (
      <Box sx={{ mt: 1 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card variant='outlined'>
            <CardContent>
              <Stack direction='row' spacing={2}>
                <Typography variant='h6'>Tour Schedule</Typography>

                {loading && (
                  <CircularProgress size={24} sx={{ ml: theme.spacing(1) }} />
                )}
              </Stack>

              <FundsCard sx={{ minWidth: 300, mb: theme.spacing(2) }} />

              <Box>
                {!noDatesAvailable && (
                  <DateCalendar
                    disabled={loading || noDatesAvailable}
                    disablePast
                    shouldDisableDate={day => {
                      // return true means disable the date
                      const dayIso = day.toISODate()
                      return dayIso ? !isoDates.includes(dayIso) : true
                    }}
                    value={currentActiveDate}
                    onChange={(newValue: DateTime) => {
                      setSelectedDate(null)
                      setActiveDate(newValue)
                    }}
                  />
                )}

                {!loading && noDatesAvailable && (
                  <Alert
                    variant='filled'
                    severity='warning'
                    sx={{ my: theme.spacing(2) }}
                  >
                    No tours are available
                  </Alert>
                )}
              </Box>

              <Box>
                <Grid container spacing={2}>
                  {slots.map(slot => {
                    const time = slot.toLocaleString(DateTime.TIME_SIMPLE)
                    const isSelected =
                      selectedDate?.toLocaleString(DateTime.TIME_SIMPLE) ===
                      time
                    return (
                      <Grid key={time}>
                        <Chip
                          disabled={loading}
                          label={time}
                          color='primary'
                          variant={isSelected ? 'filled' : 'outlined'}
                          onClick={() =>
                            setSelectedDate(isSelected ? null : slot)
                          }
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>

              {!loading && !noDatesAvailable && (
                <Box sx={{ mt: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>
                      Are you a real estate agent representing a buyer?
                    </Typography>
                    <Checkbox
                      checked={isAgent}
                      onChange={event => setIsAgent(event.target.checked)}
                    />
                  </Box>

                  {isAgent && (
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          required
                          label='License Number'
                          id='agent-license'
                          variant='outlined'
                          size='small'
                          error={!!error}
                          helperText={
                            error?.type === 'maxLength'
                              ? 'Max Length 20'
                              : error?.message
                          }
                          sx={{ mt: 1, width: '100%' }}
                        />
                      )}
                      name='agent_license'
                      control={control}
                      defaultValue={agent_license.toString()}
                      rules={{ required: 'Required', maxLength: 20 }}
                    />
                  )}
                </Box>
              )}
            </CardContent>

            <Divider />

            {selectedDate ? (
              <Box
                sx={{
                  marginRight: theme.spacing(2),
                  marginLeft: theme.spacing(2),
                  marginTop: theme.spacing(1),
                }}
              >
                <Typography variant='h6'>
                  {selectedDate.toFormat('cccc, LLL d, t')} -{' '}
                  {selectedDate.plus({ minutes: 45 }).toFormat('t  ZZZZ')}
                </Typography>
              </Box>
            ) : null}

            <CardActions
              sx={{ alignItems: 'stretch', justifyContent: 'flex-end' }}
            >
              <Stack
                direction='row'
                spacing={2}
                sx={{ p: theme.spacing(1), alignItems: 'center' }}
              >
                <Button
                  variant='contained'
                  color='error'
                  onClick={() => exit()}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  color='success'
                  startIcon={<McIcon path='calendar-plus' />}
                  disabled={!agentValid || !selectedDate || loading}
                  type='submit'
                >
                  Request Tour
                </Button>

                {loading && (
                  <Box>
                    <CircularProgress size={24} sx={{ ml: theme.spacing(1) }} />
                  </Box>
                )}
              </Stack>
            </CardActions>
          </Card>
        </form>
      </Box>
    )
  }
)
