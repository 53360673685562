import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { userBulkFetchUserProfiles } from "../../services"
import { ProfileModel } from "../Account/Profile"
import { withSetPropAction } from "../withSetPropAction"

export const ChatStoreModel = types
  .model("ChatStore")
  .props({
    profiles: types.optional(types.array(ProfileModel), []),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    async getProfiles({ userIds, refresh = false }: { userIds: string[]; refresh?: boolean }) {
      const result: {
        errorDetails?: string | null
        profiles: { [key: string]: { name?: string; pic?: string; agent_license?: number | null } }
      } = { profiles: {} }

      const newUserIds: string[] = []

      if (refresh) {
        newUserIds.push(...userIds)
      } else {
        for (const userId of userIds) {
          const profile = store.profiles.find((p) => p.user_id === userId)
          if (profile) {
            result.profiles[userId] = {
              name: profile.name || undefined,
              pic: profile.pic || undefined,
            }
          } else {
            newUserIds.push(userId)
          }
        }
      }

      if (newUserIds.length > 0) {
        const { data, error } = await userBulkFetchUserProfiles({ body: { ids: newUserIds } })

        if (error) {
          result.errorDetails = Array.isArray(error.detail)
            ? error.detail.map((e) => e.msg).join(", ")
            : error.detail
        } else if (data) {
          const newRecords = data.map((record) => {
            result.profiles[record.user_id] = {
              name: record.name || undefined,
              pic: record.pic || undefined,
              agent_license: record.agent_license,
            }
            return ProfileModel.create(record)
          })

          if (newRecords.length > 0) {
            if (refresh) {
              store.setProp("profiles", newRecords)
            } else {
              store.setProp("profiles", [...store.profiles, ...newRecords])
            }

            return result
          }
        }
      }

      return result
    },
  }))
  .actions((store) => ({
    async refreshProfiles() {
      const userIds: string[] = []

      for (const prof of store.profiles) {
        if (prof.user_id) {
          userIds.push(prof.user_id)
        }
      }

      await store.getProfiles({ userIds, refresh: true })

      return null
    },
  }))
  .views((store) => ({
    profileByUserId(userId: string) {
      return store.profiles.find((p) => p.user_id === userId)
    },
  }))

export interface ChatStore extends Instance<typeof ChatStoreModel> {}
export interface ChatStoreSnapshot extends SnapshotOut<typeof ChatStoreModel> {}
