import { useMediaQuery } from '@mui/material'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useEffect } from 'react'
import { useStores } from '../models'
import { localStore, LocalStoreKey, LocalStoreOp } from '../utils/storage'

export function useStartupActions() {
  const rootStore = useStores()
  const { accountStore, notificationStore } = rootStore
  const loggedIn = accountStore.user.loggedIn
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const isDarkMode = accountStore.darkMode
  const auth = getAuth()

  useEffect(() => {
    const savedTheme = localStore(LocalStoreOp.GET, LocalStoreKey.THEME)

    let darkMode = isDarkMode
    if (savedTheme) {
      darkMode = savedTheme === 'dark'
    } else {
      darkMode = prefersDarkMode
      localStore(LocalStoreOp.SET, LocalStoreKey.THEME, 'dark')
    }

    accountStore.setProp('darkMode', darkMode)
  }, [accountStore, prefersDarkMode, isDarkMode])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        const accessToken = await user.getIdToken()

        accountStore.setProp('user', {
          ...accountStore.user,
          email: user.email,
          name: user.displayName,
          picture: user.photoURL,
          userId: user.uid,
          loggedIn: true,
          accessToken,
        })

        accountStore.setProp('loaded', true)
      } else {
        try {
          await auth.signOut()
        } catch (error) {
          console.error(`Sign Out Error: ${error}`)
        }
        accountStore.reset()
      }
    })

    return () => unsubscribe()
  }, [auth, rootStore, accountStore])

  useEffect(() => {
    if (loggedIn && accountStore.user && accountStore.user.userId) {
      accountStore.user.fetchProfile()
      notificationStore.fetchNotifications({})
    }
  }, [notificationStore, accountStore.user, loggedIn])

  return null
}
