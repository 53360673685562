import { Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'
import * as React from 'react'
import { useTheme } from '../../../theme'

interface GlowingIconContainerProps {
  icon: React.ReactNode
}

export function GlowingIconContainer({ icon }: GlowingIconContainerProps) {
  const theme = useTheme()

  const diffStyles =
    theme.palette.mode === 'dark'
      ? {
          borderColor: alpha(theme.palette.primary.main, 0.25),
          bgcolor: alpha(theme.palette.primary.main, 0.2),
          boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.1)}, 0px 2px 12px 0px rgba(0, 0, 0, 0.25) inset`,
        }
      : {
          borderColor: alpha(theme.palette.primary.main, 0.25),
          bgcolor: alpha(theme.palette.primary.main, 0.05),
          boxShadow: `0px 0 0 2px ${alpha(theme.palette.primary.main, 0.1)}, 0px 2px 12px 0px rgba(234, 237, 241, 0.3) inset`,
          '& .MuiSvgIcon-root': {
            fontSize: theme.typography.pxToRem(18),
          },
        }

  return (
    <Box
      sx={{
        width: 36,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        borderRadius: 3,
        border: '1px solid',
        ...diffStyles,
      }}
    >
      {icon}
    </Box>
  )
}

interface InfoCardProps {
  classNameDescription?: string
  classNameTitle?: string
  description?: string
  icon?: React.ReactNode
  link?: string
  svg?: React.ReactNode
  title: string
  titleProps?: TypographyProps
}

export function InfoCard(props: InfoCardProps) {
  const {
    classNameDescription,
    classNameTitle,
    description,
    icon,
    title,
    titleProps,
  } = props
  const theme = useTheme()

  const diffStyles =
    theme.palette.mode === 'dark'
      ? {
          bgcolor: alpha(theme.palette.primary.main, 0.25),
          borderColor: theme.palette.primary.main,
          background: `linear-gradient(0deg, ${alpha(theme.palette.primary.light, 0.1)}, ${alpha(theme.palette.primary.dark, 0.1)})`,
        }
      : {
          bgcolor: alpha(theme.palette.primary.main, 0.05),
          borderColor: alpha(theme.palette.primary.main, 0.25),
          background: `linear-gradient(0deg, ${alpha(theme.palette.primary.dark, 0.1)}, ${alpha(theme.palette.primary.light, 0.1)})`,
        }

  return (
    <Paper
      variant='outlined'
      sx={{
        p: 2.5,
        height: '100%',
        borderRadius: 3,
        ...diffStyles,
      }}
    >
      {icon && <GlowingIconContainer icon={icon} />}
      <Typography
        fontWeight='bold'
        component='h3'
        color='text.primary'
        variant='body2'
        mt={icon ? 2 : 0}
        mb={description ? 0.5 : 0}
        className={classNameTitle}
        {...titleProps}
      >
        {title}
      </Typography>
      <Typography
        variant='body2'
        color='text.secondary'
        className={classNameDescription}
      >
        {description}
      </Typography>
    </Paper>
  )
}
