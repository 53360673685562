import { Button, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AndroidGooglePlayDark from '../../assets/android_google_play_dark.svg'
import AppleAppStoreDark from '../../assets/apple_app_store_dark.svg'
import { ValueProps } from './ValueProps'

export function LandingPage() {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Homy'
  }, [])

  return (
    <Box
      id='hero'
      sx={() => ({
        width: '100%',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 4, sm: 6 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant='h1'
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Home Buying,&nbsp;
            <Typography
              component='span'
              variant='h1'
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: theme =>
                  theme.palette.mode === 'light'
                    ? 'primary.main'
                    : 'primary.light',
              }}
            >
              Simplified
            </Typography>
          </Typography>
        </Stack>

        <Button
          size='large'
          sx={theme => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha(theme.palette.primary.light, 0.2)}`
                : `0 0 24px 12px ${alpha(theme.palette.primary.dark, 0.2)}`,
            pr: 6,
            pl: 6,
          })}
          onClick={() => navigate('/listings')}
        >
          View Listings
        </Button>

        <Box sx={{ mt: 20 }}>
          <ValueProps />
        </Box>

        <Box sx={{ mt: 20, display: 'flex' }}>
          <Box sx={{ mr: 1 }}>
            <a
              href='https://apps.apple.com/us/app/homy-sell-buy-homes/id6686408403?platform=iphone'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={AppleAppStoreDark} height={40} />
            </a>
          </Box>
          <a
            href='https://play.google.com/store/apps/details?id=net.homyapp'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={AndroidGooglePlayDark} height={40} />
          </a>
        </Box>

        <Typography variant='body2' sx={{ mt: 2 }}>
          © {new Date().getFullYear()} Homy LLC, All Rights Reserved
        </Typography>
      </Container>
    </Box>
  )
}
