import { Box, Typography } from '@mui/material'
import { McIcon } from '../../../Components'

export const Security = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      mt: 1,
    }}
  >
    <McIcon path='lock' size={0.75} />{' '}
    <Typography variant='body2' sx={{ ml: 1 }}>
      Data is secured with TLS and AES-256 encryption
    </Typography>
  </Box>
)
