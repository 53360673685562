import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Counter, OfferStatus } from '../../../../../shared/models'
import { formatCurrency } from '../../../../../shared/utils'
import { useApiCallState } from '../../../hooks'
import { useStores } from '../../../models'
import { useTheme } from '../../../theme'
import { DocCard } from '../Docs/DocCard'

const statusColorMap = {
  [OfferStatus.ACCEPTED]: 'success',
  [OfferStatus.ACTIVE]: 'primary',
  [OfferStatus.CANCELLED]: 'warning',
  [OfferStatus.COUNTERED]: 'secondary',
  [OfferStatus.DECLINED]: 'error',
}

interface OfferDetailsProps {
  offerId: string
  newOffer?: (counter?: Counter | null) => void
  newTour?: () => void
}

export const OfferDetails: React.FC<OfferDetailsProps> = observer(
  ({ offerId, newOffer, newTour }) => {
    const { offerStore } = useStores()
    const theme = useTheme()
    const [refreshTick, setRefreshTick] = useState(0)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const { loading, setLoading, setMsg } = useApiCallState()

    const offer = offerStore.offerById(offerId)
    if (!offer) return null

    // @ts-expect-error Correct type
    const statusColor = statusColorMap[offer.status] || 'primary'

    useEffect(() => {
      const intervalId = setInterval(
        () => setRefreshTick(current => current + 1),
        10 * 1000 // Every 10 seconds
      )

      return () => {
        clearInterval(intervalId)
      }
    }, [])

    return (
      <>
        <Card variant='outlined'>
          <CardHeader
            title='Your Offer'
            subheader={
              <>
                <Typography id={`created-${refreshTick}`}>
                  {DateTime.fromJSDate(offer.created_at).toRelative()}
                </Typography>
                {offer.updated_at && (
                  <Typography variant='body2' id={`updated-${refreshTick}`}>
                    updated {DateTime.fromJSDate(offer.updated_at).toRelative()}
                  </Typography>
                )}
              </>
            }
            action={
              <Chip
                label={offer.status}
                color={statusColor}
                sx={{ mr: theme.spacing(1) }}
              />
            }
          />
          <CardContent>
            <Typography>Purchase Price</Typography>
            <Typography variant='h5' sx={{ mb: theme.spacing(2) }}>
              {formatCurrency(offer.price)}
            </Typography>

            <Stack direction='row' spacing={3}>
              <Box>
                <Typography variant='body2'>Earnest Money</Typography>
                <Typography variant='h6'>
                  {formatCurrency(offer.earnest)}
                </Typography>
              </Box>

              <Box>
                <Typography variant='body2'>Due Dilligence Fee</Typography>
                <Typography variant='h6'>
                  {formatCurrency(offer.due_dilligence)}
                </Typography>
              </Box>

              {!!offer.agent_commission && offer.agent_commission > 0 ? (
                <Box>
                  <Typography variant='body2'>Agent Commission</Typography>
                  <Typography variant='h6'>
                    {formatCurrency(offer.agent_commission)}
                  </Typography>
                </Box>
              ) : null}
            </Stack>

            <DocCard offerId={offerId} sx={{ minWidth: 300 }} />

            {offer.status === OfferStatus.COUNTERED && offer.counter && (
              <Box>
                <Divider sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }} />
                <Typography variant='h5' sx={{ mb: theme.spacing(1) }}>
                  Counter Proposal
                </Typography>

                <Stack direction='row' spacing={3}>
                  <Box>
                    <Typography variant='body2'>Purchase Price</Typography>
                    <Typography variant='h6'>
                      {formatCurrency(offer.counter.price)}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant='body2'>Earnest Money</Typography>
                    <Typography variant='h6'>
                      {formatCurrency(offer.counter.earnest)}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant='body2'>Due Dilligence Fee</Typography>
                    <Typography variant='h6'>
                      {formatCurrency(offer.counter.due_dilligence)}
                    </Typography>
                  </Box>
                </Stack>

                {offer.counter.comments && (
                  <Typography sx={{ mt: theme.spacing(1) }}>
                    {offer.counter.comments}
                  </Typography>
                )}
              </Box>
            )}

            {offer.status === OfferStatus.ACTIVE && (
              <Alert severity='info' variant='filled' sx={{ mt: 2 }}>
                <Typography variant='body1'>
                  The seller has recieved your offer! You will recieve an email
                  notification when they make a decision to accept, counter or
                  decline.
                </Typography>
              </Alert>
            )}
          </CardContent>

          <CardActions>
            {(offer.status === OfferStatus.ACTIVE ||
              offer.status === OfferStatus.COUNTERED) && (
              <Button color='error' onClick={() => setConfirmDelete(true)}>
                Cancel Offer
              </Button>
            )}

            {(offer.status === OfferStatus.DECLINED ||
              offer.status === OfferStatus.CANCELLED) && (
              <>
                <Button color='success' onClick={() => newOffer && newOffer()}>
                  Submit New Offer
                </Button>
                <Button color='primary' onClick={() => newTour && newTour()}>
                  Request Tour
                </Button>
              </>
            )}

            {offer.status === OfferStatus.COUNTERED && (
              <Button
                color='success'
                onClick={() => newOffer && newOffer(offer.counter)}
              >
                Create Counter Offer
              </Button>
            )}
          </CardActions>
        </Card>

        {confirmDelete && (
          <Dialog
            open={confirmDelete}
            onClose={() => setConfirmDelete(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              Are you sure you want to remove your offer?
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => setConfirmDelete(false)}
                disabled={loading}
              >
                No
              </Button>
              <Button
                onClick={async () => {
                  setLoading(true)
                  offer.setProp('status', OfferStatus.CANCELLED)
                  const { error } = await offer.updateOffer()

                  if (error) {
                    console.error(error)
                    setMsg(error, 'error')
                  } else {
                    setMsg('Offer cancelled', 'success')
                  }
                  setConfirmDelete(false)
                  setLoading(false)
                }}
                autoFocus
                color='error'
                disabled={loading}
              >
                Yes{' '}
                {loading && (
                  <CircularProgress size={24} sx={{ ml: theme.spacing(1) }} />
                )}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    )
  }
)
