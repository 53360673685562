import {
  ArticleRounded,
  EditCalendarRounded,
  FolderCopyRounded,
} from '@mui/icons-material'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { InfoCard } from './InfoCard'

const content = [
  {
    icon: <EditCalendarRounded fontSize='small' color='primary' />,
    title: 'Effortless Home Tour Scheduling',
    description: `Book, reschedule, or cancel tours with a single click. Choose times that fit your schedule.`,
  },
  {
    icon: <ArticleRounded fontSize='small' color='primary' />,
    title: 'Streamlined Offer Submission',
    description:
      'Submit offers directly from the listing page using the standard contract form. Track offer status in real-time.',
  },
  {
    icon: <FolderCopyRounded fontSize='small' color='primary' />,
    title: 'Hassle-Free Document Handling',
    description:
      'Upload required documents right on the listing page when needed. No more guesswork or lost emails.',
  },
]

export function ValueProps() {
  return (
    <Box component='section'>
      <Grid container spacing={3}>
        {content.map(({ icon, title, description }) => (
          <Grid key={title} size={{ xs: 12, sm: 4 }}>
            <InfoCard title={title} icon={icon} description={description} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
